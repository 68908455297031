import React, {useEffect, useState} from 'react';
import Screen from '../components/Screen';
import '../styles/style.css';
import {useOutletContext} from "react-router-dom";
import moment from "moment";
import axios from "axios";

const Tasks = () => {
	const user = useOutletContext();
	const [meta, setMeta] = useState(null); // meta állapot
	const [isLoading, setIsLoading] = useState(true); // Töltési állapot
	const token = localStorage.getItem('token');

	const isAdminOrBoss = user.role === 'admin' || user.role === 'boss'

	const copyNextday = async () => {
		try {
			const response = await axios.post('/manualjobs/admin/jobs/manual-run', {}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
			});

			// Sikeres válasz kezelése
			alert(response.data || 'Job sikeresen feladva!');
		} catch (error) {
			// Hiba kezelése
			if (error.response) {
				// A szerver válaszolt hibakóddal
				alert(`Hiba történt: ${error.response.data || error.response.statusText}`);
			} else if (error.request) {
				// A kérés elment, de nincs válasz
				alert('Nincs válasz a szervertől.');
			} else {
				// Valami más hiba történt
				alert(`Hálózati hiba: ${error.message}`);
			}
		}
	};

/*	const closeTask = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('closeTask' + rowId);
	};*/
/*	const handleSpecRow1 = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('handleSpec1: ' + rowId);
	};
	const handleSpecRow2 = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('handleSpec2: ' + rowId);
	};*/

	const gridActions = (user.role === 'admin' || user.role === 'boss')
		? [{ label: 'Feladat menedzser', onClick: copyNextday, isEnableReadOnly: false }]
		: [];

	const rowActions = [
		/*{ label: 'Feladat zárása', onClick: closeTask,isEnableReadOnly: false}*/
	];

	const detailRowActions = [
		//Nem kell frissíteni benne. Minden dinamikus gomb onclick eseménye után frissít a komponens
		/*{ label: 'Törés', onClick: delRowService, isEnableReadOnly: false },*/
	];

	const modifiedMeta = {
		id: {
			type: 'INTEGER',
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		day: {
			type: 'DATEONLY',
			label: `Munkanap`,
			allowNull: false,
			showInView: true,
			ismodify: isAdminOrBoss,
			isNewRow: true,
			order: 5,
			filterMode: 'rangedateonly',
			defaultValue: moment().format('YYYY-MM-DD'),
		},
		taskowneruserid: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
			order: 10,
		},
		taskuser_name: {
			type: 'STRING',
			allowNull: false,
			label: `Tulajdonos`,
			showInView: true,
			ismodify: isAdminOrBoss,
			isNewRow: true,
			isList: true,
			filterMode: 'list',
			apiEndpoint: '/users/opt/userName',
			orginalName: '$taskuser.name$',
			myparent: 'taskowneruserid',
			order: 15,
		},
		type: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		tasktype_parameter_value_char: {
			type: 'STRING',
			allowNull: false,
			label: `Feladat típus`,
			showInView: true,
			ismodify: isAdminOrBoss,
			isNewRow: true,
			isList: true,
			filterMode: 'list',
			apiEndpoint: '/parameters/opt/parameterName?parameter_type=tasktype',
			orginalName: '$tasktype.parameter_value_char$',
			myparent: 'type',
			order: 20,
		},

		taskdesc: {
			type: 'STRING',
			label: `Feladat leírás`,
			allowNull: false,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 25,
		},
		status: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
			defaultValue: 'open',
		},
		taskstatus_parameter_value_char: {
			type: 'STRING',
			allowNull: false,
			label: `Státusz`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			isList: true,
			defaultValue: 'nyitott',
			filterMode: 'list',
			apiEndpoint: '/parameters/opt/parameterName?parameter_type=taskstatus',
			orginalName: '$taskstatus.parameter_value_char$',
			myparent: 'status',
			conditionalFormat: {if: `rowValue === 'lezárt'`, style: {backgroundColor: '#bcffbc !important'}},
			order: 30,
			preCheck: [{
					preCheckValue: 'lezárt',
					field: 'donepercent',
					expectedValue: '100',
					isExpectedValueNegal: false,
					message: `Ha a státusz 'lezárt', akkor a készültségnek 100%-nak kell lennie.`,
				},
				{
					preCheckValue: 'lezárt',
					field: 'daytime',
					expectedValue: '00:00:00',
					isExpectedValueNegal: true,
					message: `Ha a státusz 'lezárt', akkor a ráfordítási idő nem lehet 00:00.`,
				},
				{
					preCheckValue: 'függő',
					field: 'donepercent',
					expectedValue: 100,
					isExpectedValueNegal: true,
					message: `Ha a státusz 'függő', akkor a készültségi nem lehet 100%.`,
				},
				],
		},
		priority: {
			type: 'INTEGER',
			label: `Priorítás`,
			allowNull: false,
			showInView: true,
			ismodify: isAdminOrBoss,
			isNewRow: true,
			min: 0,
			max: 9,
			defaultValue: '3',
			filterMode: 'rangenumber',
/*			filterOffGenUser: true, //csak admmin és boss szűrhet.
			sortOffGenUser: true, //csak admmin és boss szűrhet.*/
			order: 35,
		},
		daytime: {
			type: 'TIME',
			label: `Napi ráfordítás`,
			allowNull: false,
			defaultValue: '00:00:00',
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'rangetime',
			order: 40,
		},
		donepercent: {
			type: 'INTEGER',
			label: `Készültség`,
			allowNull: false,
			defaultValue: '0',
			showInView: true,
			ismodify: true,
			min: 0,
			max: 100,
			isNewRow: true,
			filterMode: 'rangenumber',
			order: 45,
/*			preCheck: [{
				preCheckValue: 100,
				field: 'status',
				expectedValue: 'closed',
				isExpectedValueNegaltion: false,
				message: `Ha a készültségi 100%, akkor a státusznak 'lezárt'-nak kell lennie.`,
			}],*/
		},
		deadline: {
			type: 'DATEONLY',
			label: `Határidő`,
			allowNull: false,
			showInView: true,
			ismodify: isAdminOrBoss, // Csak admin vagy editor módosíthatja
			isNewRow: true,
			filterMode: 'rangedateonly',
			defaultValue: moment().add(7, 'days').format('YYYY-MM-DD'),
			conditionalFormat: {if: 'new Date(rowValue) < new Date()', style: {backgroundColor: '#f19b9b !important'}},
			order: 50,
		},
		createdAt: {
			type:'DATE',
			allowNull: false,
			label: `Létrehozás`,
			showInView: true,
			ismodify: false,
			isNewRow: false,
			filterMode: 'rangedate',
			order: 55,
		},
		comment: {
			type: 'TEXT',
			isMemo: true,
			allowNull: true,
			label: `Megjegyzés`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 99,
		},
		updated_user: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		updateUser_name: {
			type: 'STRING',
			label: `Utolsó módosító`,
			showInView: true,
			allowNull: false,
			ismodify: false,
			isNewRow: false,
			filterMode: 'list',
			apiEndpoint: '/users/opt/userName',
			orginalName: '$updateUser.name$',
			myparent: 'updated_user',
			order: 91,
		},
		updatedAt: {
			label: `Modosítás ideje`,
			type: 'DATE',
			allowNull: false,
			showInView: true,
			ismodify: false,
			isNewRow: false,
			filterMode: 'rangedate',
			order: 92,
		},
	};

	useEffect(() => {
		setMeta(modifiedMeta);
		setIsLoading(false);
	}, []);

	// Töltés jelzése, amíg a `meta` be nem töltődik
	if (isLoading) {
		return <div>Töltés...</div>;
	}

	return (
		<Screen
			title="Feladatok kezelése"
			gridActions={gridActions}
			rowActions={rowActions}
			detailRowActions={detailRowActions}
			readonly={false}
			model={'tasks'}
			meta={meta}
			user={user}
			options={{
				enableDelete: isAdminOrBoss, // Csak admin vagy boss
				enableAdd: isAdminOrBoss // Csak admin vagy editor
			}}
		/>
	);
};

export default Tasks;