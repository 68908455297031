import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './screens/Login';
import Users from './screens/Users';
import Parameters from "./screens/Parameters";
import Emails from './screens/Emails';
import Tasks from './screens/Tasks';
import ResetPasswordPage from "./screens/ResetPasswordPage";
import MainLayout from './components/MainLayout';
import './styles/style.css';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.baseURL = 'http://kontener.4profitmarketing.hu:5000';
axios.defaults.headers.common['Authorization'] = `Bearer ${(localStorage.getItem('token'))}`;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/*" element={<ProtectedRoutes />} /> {/* Védett útvonalak */}
      </Routes>
    </Router>
  );
}

// Token ellenőrzést itt végezzük el
function ProtectedRoutes() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      console.log('checktoken', token);
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        console.log('/check-token előtt',localStorage.getItem('token'));
        const response = await axios.get('/auth/check-token');
        console.log('/auth/check-token után', response);
        if (!response.data.valid) {
          console.log('sikertelen');
          navigate('/login');
        } else {
          console.log('sikeres');
          setUser(response.data.user); // Felhasználó adatainak mentése
        }
      } catch (error) {
        console.error('Token verification failed', error);
        navigate('/login');
      }
    };

    checkToken();
  }, [navigate]);

  if (!user) {
    return null; // Amíg a felhasználó adatok töltődnek, nem jelenítünk meg semmit
  }

  console.log('App:' + user.id);
  console.log('App:' + process.env.REACT_APP_ENV);

  return (
    <Routes>
      <Route path="/" element={<MainLayout user={user} />}>
        <Route path="users" element={<Users user={user}/>} />
        <Route path="parameters" element={<Parameters />} />
        <Route path="tasks" element={<Tasks />} />
        <Route path="emails" element={<Emails />} />
      </Route>
    </Routes>
  );
}

export default App;


