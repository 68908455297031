import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

const ResetPasswordPage = () => {
	const { token } = useParams(); // Token kinyerése az URL-ből
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
  
	const handleSubmit = async (e) => {
	  	e.preventDefault();
	  	setError("");
	  	setSuccessMessage("");
  
	  	if (password !== confirmPassword) {
			setError("A jelszavak nem egyeznek!");
			return;
	  	}
  
	 	try {
			const response = await axios.post(
		  	process.env.REACT_APP_API_URL+`/auth/reset-password/${token}`,
		  		{ password }
			);
			setSuccessMessage("Sikeres jelszóváltoztatás!");
	  	} catch (err) {
			setError(
		  		err.response?.data?.message || "Hiba történt a jelszó módosítása során."
			);
		}
	};
  
	return (
	  <div style={{ maxWidth: "400px", margin: "auto", padding: "20px" }}>
		<h2>Jelszó visszaállítása</h2>
		{error && <p style={{ color: "red" }}>{error}</p>}
		{successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
		<form onSubmit={handleSubmit}>
		  <div style={{ marginBottom: "10px" }}>
			<label htmlFor="password">Új jelszó</label>
			<input
			  type="password"
			  id="password"
			  value={password}
			  onChange={(e) => setPassword(e.target.value)}
			  required
			  style={{ width: "100%", padding: "8px" }}
			/>
		  </div>
		  <div style={{ marginBottom: "10px" }}>
			<label htmlFor="confirmPassword">Jelszó megerősítése</label>
			<input
			  type="password"
			  id="confirmPassword"
			  value={confirmPassword}
			  onChange={(e) => setConfirmPassword(e.target.value)}
			  required
			  style={{ width: "100%", padding: "8px" }}
			/>
		  </div>
		  <button
			type="submit"
			style={{
			  width: "100%",
			  padding: "10px",
			  backgroundColor: "#007BFF",
			  color: "white",
			  border: "none",
			  cursor: "pointer",
			}}
		  >
			Küldés
		  </button>
		</form>
	  </div>
	);
  };
  
  export default ResetPasswordPage;