import React, { useState, useEffect  } from 'react';
import TriStateToggle from './TriStateToggle';
import Dropdown from './Dropdown';
import AlertModal from './AlertModal';
import moment from 'moment';
/*import { getDynamicListOptions } from './api';*/

const HelperScreen = ({ isOpen, actionType, rowData, onClose, onSave, meta, title , dynamicOptions}) => {
	const [textValue, setTextValue] = useState(rowData || {});
	const [alertMessage, setAlertMessage] = useState('');
	const [isAlertOpen, setIsAlertOpen] = useState(false);

	useEffect(() => {
		//console.log("dynamicOptions",dynamicOptions);
		if (actionType === 'add') {
			const initialValues = Object.keys(meta).reduce((acc, key) => {
				console.log("meta[key].defaultValue",meta[key].defaultValue);
				acc[key] = meta[key].defaultValue || '';
				return acc;
			}, {});
			console.log("rowdata",rowData);
			if (rowData && Object.keys(rowData).length > 0) {
				setTextValue(rowData);
				console.log("initialValues",initialValues);
			} else {
				console.log("initialValues",initialValues);
				setTextValue(initialValues);
			}
		} else {
			console.log("helperrowData", rowData);

			// Végigjárjuk az összes kulcsot a meta objektumban
			const updatedRowData = { ...rowData }; // Az eredeti rowData másolatának létrehozása
			Object.keys(meta).forEach((key) => {
				if (meta[key].isforeignKeyChild) {
					const columnPath = meta[key].showColumnName.split('.'); // Pl.: ['Customer', 'name']
					let value = rowData;

					// Végigjárjuk a showColumnName által megadott útvonalat
					for (const pathPart of columnPath) {
						if (value && value[pathPart] !== undefined) {
							value = value[pathPart];
						} else {
							value = undefined;
							break;
						}
					}

					updatedRowData[key] = value;
				}
			});

			// Végül beállítjuk a módosított rowData-t
			setTextValue(updatedRowData || {});
		}
	}, [actionType, meta, rowData]);


	const validateForm = () => {
		let formErrors = {};

		// 1. Kötelező mezők ellenőrzése
		Object.keys(meta).forEach((key) => {
			if (
				(actionType === 'modify' && meta[key].ismodify && !meta[key].allowNull) ||
				(actionType === 'add' && meta[key].isNewRow && !meta[key].allowNull)
			) {
				if (textValue[key] === '' || textValue[key] === null || textValue[key] === undefined) {
					formErrors[key] = `${meta[key].label} is required`;
				}
			}
		});

		// 2. `preCheck` szabályok ellenőrzése
		Object.entries(meta).forEach(([field, fieldMeta]) => {
			if (fieldMeta.preCheck) {
				fieldMeta.preCheck.forEach((rule) => {
					const { preCheckValue, field: relatedField, expectedValue, isExpectedValueNegal, message } = rule;

					// Negált feltétel kezelése
					const relatedValue = textValue[relatedField];
					const isInvalid = isExpectedValueNegal
						? relatedValue === expectedValue // Ha negált, akkor az elvárt érték nem teljesülhet
						: relatedValue !== expectedValue; // Normál ellenőrzés

					if (textValue[field] === preCheckValue && isInvalid) {
						formErrors[field] =
							message ||
							`Hiba: Ha a(z) "${field}" értéke "${preCheckValue}", akkor a(z) "${relatedField}" értékének ${
								isExpectedValueNegal ? 'nem lehet' : 'kell lennie'
							} "${expectedValue}".`;
					}
				});
			}
		});

		// 3. Hibák megjelenítése, ha vannak
		if (Object.keys(formErrors).length > 0) {
			const errorMessage = Object.values(formErrors).join('\n');
			setAlertMessage(errorMessage);
			setIsAlertOpen(true);
			return false;
		}

		return true; // Ha nincs hiba, a form érvényes
	};


	const handleChange = (e) => {
		const { name, value } = e.target;

		let formattedValue = value;

		if (meta[name] && meta[name].type === 'DATE') {
			// Dátum típusú mezők esetén ISO formátumra konvertálunk
			formattedValue = moment(value).isValid() ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : value;
		} else if (meta[name] && meta[name].type === 'DATEONLY') {
			// Csak dátumot igénylő mezők esetén 'YYYY-MM-DD' formátumot használunk
			formattedValue = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : value;
		}

		setTextValue({
			...textValue,
			[name]: formattedValue,
		});
	};


	const handleListChange = (key, value) => {
		setTextValue({
			...textValue,
			[key]: value === '' ? null : value,
		});
	};

	const handleBooleanChange = (key, value) => {
		setTextValue({
			...textValue,
			[key]: value,
		});
	};

	const handleSave = (e) => {
		e.preventDefault();

		if (!validateForm()) {
			// Ha a form nem érvényes, egyszerűen visszatérünk
			return;
		}

		let returnfields;

		if (actionType === 'modify') {
			returnfields = Object.keys(textValue)
				.filter(key => meta[key] && (meta[key].ismodify || key === 'id'))
				.reduce((acc, key) => {
					const originalValue = rowData[key];
					const newValue = textValue[key];

					// Csak akkor adja hozzá, ha az érték megváltozott
					if (originalValue !== newValue || key === 'id') {
						if (meta[key] && meta[key].isList) {
							acc[meta[key].myparent] = dynamicOptions[key]
								.filter(item => item.value === newValue)
								.map(item => item.id)[0];
						} else {
							acc[key] = Array.isArray(newValue)
								? newValue[0]
								: typeof newValue === 'object'
									? JSON.stringify(newValue)
									: newValue;
						}

					}

					return acc;
				}, {});
		} else {
			returnfields = Object.keys(textValue)
				.filter(key => meta[key] && meta[key].isNewRow)
				.reduce((acc, key) => {
					if (meta[key] && meta[key].isList) {
						acc[meta[key].myparent] = dynamicOptions[key]
							.filter(item => item.value === textValue[key])
							.map(item => item.id)[0];
					}
					else {
						acc[key] = Array.isArray(textValue[key])
							? textValue[key][0]
							: typeof textValue[key] === 'object'
								? JSON.stringify(textValue[key])
								: textValue[key];
					}
					return acc;
				}, {});
		}

		console.log("returnfields",returnfields); //itt a hiba

		onSave(returnfields);
	};



	const closeAlert = () => {
		setIsAlertOpen(false);
	};

	if (!isOpen) return null;


	return (
		<div className="helper-modal">
			<div className="helper-modal-content">
				<div className="helper-header">
					<h2>{title} {actionType === 'show' ? 'megjelenítése' : actionType === 'add' ? 'hozzáadása' : 'módosítása'}</h2>
					<button className="close-button" type="button" onClick={onClose}>X</button>
				</div>
				<form onSubmit={handleSave}>
					<div className="helper-text-data-container">
						{Object.keys(meta).map((key, index) => {
							const isLook = ((actionType === 'show' && meta[key].showInView) ||
								(actionType === 'modify' && meta[key].ismodify) ||
								(actionType === 'add' && meta[key].isNewRow));
							return (
								meta[key].isList && isLook ? (
									<div key={index} className="helper-dropdown-field">
										<label>
											{meta[key].label}
											{!meta[key].allowNull ? ' *' : ''}
										</label>
										<Dropdown
											className="helper-dropdown"
											classNameList="helper-dropdown-list"
											options={dynamicOptions[key] && dynamicOptions[key].length > 0 ? dynamicOptions[key].map((item) => (item.value )) : undefined} // Dinamikus vagy statikus opciók
											value={textValue[key] || ''}
											onChange={(value) => handleListChange(key, value)}
											readOnly={actionType === 'show'}
											placeHolder={actionType === 'show' ? '' : 'Search...'}
											required={!meta[key].allowNull}
										/>
									</div>
								) : meta[key].type === 'STRING' && isLook && !meta[key].isList ? (
									<div key={index} className="helper-text-field">
										<label>
											{meta[key].label}
											{!meta[key].allowNull ? ' *' : ''}
										</label>
										<textarea
											name={key}
											value={textValue[key] || ''}
											onChange={handleChange}
											readOnly={actionType === 'show'}
											required={!meta[key].allowNull}
											style={actionType === 'show' ? { backgroundColor: 'rgba(239, 239, 239, 0.3)' } : {}}
										/>
									</div>
								) : meta[key].type === 'INTEGER' && isLook && !meta[key].isList ? (
									<div key={index} className="helper-text-field_num">
										<label className={""}>
											{meta[key].label}
											{!meta[key].allowNull ? ' *' : ''}
										</label>
										<input
											name={key}
											type='number'
											min={meta[key].min}
											max={meta[key].max}
											value={textValue[key] || ''}
											onChange={handleChange}
											onKeyDown={(e) => {
												// Tiltjuk az "e", "E", "+" karaktereket
												if (['e', 'E', '+'].includes(e.key)) {
													e.preventDefault();
												}
											}}
											readOnly={actionType === 'show'}
											required={!meta[key].allowNull}
											style={actionType === 'show'	? { backgroundColor: 'rgba(239, 239, 239, 0.3)'}: {}
											}
										/>
									</div>
								) : meta[key].type === 'DATE' && isLook && !meta[key].isList ? (
								<div key={index} className="helper-text-field_datetime">
									<label className={""}>
										{meta[key].label}
										{!meta[key].allowNull ? ' *' : ''}
									</label>
									<input
										name={key}
										type='datetime-local'
										value={textValue[key] || ''}
										onChange={handleChange}
										readOnly={actionType === 'show'}
										required={!meta[key].allowNull}
										style={actionType === 'show'	? { backgroundColor: 'rgba(239, 239, 239, 0.3)'}: {}
										}
									/>
								</div>) : meta[key].type === 'DATEONLY' && isLook && !meta[key].isList ? (
									<div key={index} className="helper-text-field_date">
										<label className={""}>
											{meta[key].label}
											{!meta[key].allowNull ? ' *' : ''}
										</label>
										<input
											name={key}
											type='date'
											value={textValue[key] || ''}
											onChange={handleChange}
											readOnly={actionType === 'show'}
											required={!meta[key].allowNull}
											style={actionType === 'show'	? { backgroundColor: 'rgba(239, 239, 239, 0.3)'}: {}
											}
										/>
									</div>) : meta[key].type === 'TIME' && isLook && !meta[key].isList ? (
									<div key={index} className="helper-text-field_time">
										<label className={""}>
											{meta[key].label}
											{!meta[key].allowNull ? ' *' : ''}
										</label>
										<input
											name={key}
											type='time'
											value={textValue[key] || ''}
											onChange={handleChange}
											readOnly={actionType === 'show'}
											required={!meta[key].allowNull}
											style={actionType === 'show'	? { backgroundColor: 'rgba(239, 239, 239, 0.3)'}: {}
											}
										/>
									</div>) : null
							);
						})}
					</div>
					<div className="helper-text-boolean-container"
				     style={actionType === 'show' ? { backgroundColor: 'rgba(239, 239, 239, 0.3)' } : {}}>
						{Object.keys(meta).map((key, index) => (
							meta[key].type === 'BOOLEAN' &&
								((actionType === 'show' && meta[key].showInView) ||
									(actionType === 'modify' && meta[key].ismodify) ||
									(actionType === 'add' && meta[key].isNewRow)) &&
								<div key={index} className="helper-boolean-field"
								>
									<label>
										{meta[key].label}
										{!meta[key].allowNull ? ' *' : ''}
									</label>
										<TriStateToggle
											value={textValue[key] !== undefined ? textValue[key] : null}
											onChange={(value) => handleBooleanChange(key, value)}
											readOnly={actionType === 'show'}
											/*required={!meta[key].allowNull}*/
										/>
								</div>
						))}
					</div>
					<div className="helper-textarea-data-container">
						{Object.keys(meta).map((key, index) => (
							meta[key].type === 'TEXT' &&
								((actionType === 'show' && meta[key].showInView) ||
									(actionType === 'modify' && meta[key].ismodify) ||
									(actionType === 'add' && meta[key].isNewRow)) &&
								<div key={index} className="helper-textarea-field">
									<label>
										{meta[key].label}
										{!meta[key].allowNull ? ' *' : ''}
									</label>
									<textarea
										name={key}
										rows={3}
										value={textValue[key] || ''}
										onChange={handleChange}
										readOnly={actionType === 'show'}
										required={!meta[key].allowNull}
										style={actionType === 'show' ? { backgroundColor: 'rgba(239, 239, 239, 0.3)' } : {}}
									/>
								</div>
						))}
					</div>
					{(actionType === 'add' || actionType === 'modify') && (
						<div className="helper-footer-boot">
							<button type="submit">Mentés</button>
						</div>
					)}
				</form>
			</div>
			<AlertModal
				isOpen={isAlertOpen}
				message={alertMessage}
				onClose={closeAlert}
			/>
		</div>
	);
};

export default HelperScreen;
