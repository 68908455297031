import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { calculateColumnWidth } from './DataGridMRT';
import { MenuItem } from "@mui/material";

const DetailDataGrid = ({ endpoint, contractId, detailModel, detailRowActions, readonly, options }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	// A fetchDetailData függvény most már globálisan elérhető a komponensen belül
	const fetchDetailData = async () => {
		if (!endpoint || !contractId) {
			console.error("DetailDataGrid - Missing endpoint or contractId", { endpoint, contractId });
			return;
		}
		console.log("DetailDataGrid - Fetching data with:", { endpoint, contractId });

		try {
			setLoading(true); // Töltés jelzése
			const response = await axios.get(`${endpoint}${contractId}`);
			console.log("DetailDataGrid - API Response:", response.data);
			setData(response.data);
		} catch (error) {
			console.error("DetailDataGrid - Error fetching data:", error);
		} finally {
			setLoading(false);
		}
	};

	// Az adatok betöltése a komponens mountolásakor vagy a `endpoint` és `contractId` változásokkor
	useEffect(() => {
		fetchDetailData();
	}, [endpoint, contractId]);

	useEffect(() => {
		if (detailModel === undefined || detailModel === null) {
			console.error("DetailDataGrid - detailModel is undefined or null:", detailModel);
		} else {
			console.log("DetailDataGrid - detailModel:", detailModel);
		}
	}, [detailModel]);

	// Ellenőrzés, hogy a detailModel elérhető és objektum-e
	if (!detailModel || typeof detailModel !== 'object') {
		console.error("DetailDataGrid - Invalid detailModel provided:", detailModel);
		return <div>Hiba: detailModel nem érhető el vagy nem megfelelő formátumú.</div>;
	}

	// A detailModel segítségével generáljuk a táblázat oszlopait, és kiszámoljuk a szélességüket
	const columns = Object.entries(detailModel)
		.filter(([key, attribute]) => attribute.showInView) // Csak azokat szűrjük, ahol `showInView` értéke igaz
		.map(([key, attribute]) => {
			console.log("DetailDataGrid - Creating column for key:", key, "with attribute:", attribute);
			return {
				accessorKey: key,
				header: attribute.label || key,
				enableColumnActions: false,
				enableSorting: true,
				size: calculateColumnWidth(attribute.label || key, 10),
			};
		});

	return (
	/*	<div style={{ width: `1600px` }}>*/
		<div>
			<MaterialReactTable
				columns={columns}
				data={data}
				state={{ isLoading: loading }}
				enableSorting={true}
				enableRowActions={true}
				renderRowActionMenuItems={({ closeMenu, row }) => {
					return [
						// Dinamikus gombok hozzáadása
						...detailRowActions.map((action, actionIndex) => (
							<MenuItem
								key={actionIndex}
								onClick={async () => {
									console.log('Row data onClick:', row.original); // Kiírjuk a sor adatait kattintáskor
									await action.onClick(row.original); // Meghívjuk az action-t
									await fetchDetailData(); // Újra meghívjuk a fetch-et, hogy frissítsük az adatokat
									closeMenu(); // Menüt bezárjuk
								}}
								disabled={readonly && !action.isEnableReadOnly}
							>
								{action.label}
							</MenuItem>
						))
					];
				}}
				enableColumnResizing={true}
				enableColumnFilters={false}
				enablePagination={false} // Lapozás kikapcsolása
				enableTopToolbar={false} // Fejléc menü kikapcsolása
				enableBottomToolbar={false} // Lábléc menü kikapcsolása
				muiTableBodyRowProps={{
					sx: {
						minHeight: '24px', // Minimális magasság csökkentése
						height: '32px', // Magasság csökkentése
						lineHeight: '1', // Sorok közötti távolság minimalizálása
						width: '100%', // A sorok teljes szélessége
					},
				}}
				muiRowActionsButtonProps={{
					sx: {
						padding: '0 16px 0 16px', // Cellák paddingjának csökkentése
						fontSize: '14px', // Betűméret csökkentése
						height: '20px', // Gomb magasságának beállítása
					},
				}}
				muiTableBodyCellProps={{
					sx: {
						padding: '1px 16px 1px 16px', // Cellák paddingjának csökkentése
						fontSize: '14px', // Betűméret csökkentése
					},
				}}
				muiTableContainerProps={{
					sx: {
						maxHeight: '400px', // Opcionálisan korlátozhatjuk a magasságot
						overflowY: 'auto',
					},
				}}
			/>
		</div>
	);
};

export default DetailDataGrid;
