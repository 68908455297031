import React, {useEffect, useState} from 'react';
import Screen from '../components/Screen';
import '../styles/style.css';
import {useOutletContext} from "react-router-dom";

const Parameters = () => {
	const user = useOutletContext();
	const [meta, setMeta] = useState(null); // meta állapot
	const [isLoading, setIsLoading] = useState(true); // Töltési állapot

	//spec gridgomb hozzáadás és handleSpec függvénybe griden végzett művelet visszaadása
	const gridActions = [];

	//spec gomb hozzáadás és handleSpec függvénybe soron végzett művelet visszaadása
	const rowActions = [];

	const detailRowActions = [
		//Nem kell frissíteni benne. Minden dinamikus gomb onclick eseménye után frissít a komponens
		/*{ label: 'Törés', onClick: delRowService, isEnableReadOnly: false },*/
	];

	const modifiedMeta = {
		id: {
			type: 'INTEGER',
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},

		parameter_type: {
			type: 'STRING',
			allowNull: false,
			/*defaultValue: 'defaultValue töltődikteszt user',*/
			label: `Paraméter típus`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 5,
		},
		parameter_name: {
			type: 'STRING',
			allowNull: true,
			label: `Paraméter név`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 10,
		},
		parameter_value_char: {
			type: 'STRING',
			allowNull: true,
			label: `Érték szöveg`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 15,
		},
		parameter_value_char1: {
			type: 'STRING',
			allowNull: true,
			label: `Érték szöveg1`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 20,
		},
		parameter_value_char2: {
			type: 'STRING',
			allowNull: true,
			label: `Érték szöveg2`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 25,
		},
		parameter_value_char3: {
			type: 'STRING',
			allowNull: true,
			label: `Érték szöveg3`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 30,
		},
		parameter_value_char4: {
			type: 'STRING',
			allowNull: true,
			label: `Érték szöveg4`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 35,
		},
		parameter_value_num: {
			type: 'INTEGER',
			allowNull: true,
			label: `Érték szám`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'rangenumber',
			order: 40,
		},
		parameter_value_num1: {
			type: 'INTEGER',
			allowNull: true,
			label: `Érték szám1`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'rangenumber',
			order: 45,
		},
		parameter_value_date: {
			type: 'DATEONLY',
			allowNull: true,
			label: `Érték dátum`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'rangedateonly',
			order: 50,
		},
		parameter_value_datetime: {
			type: 'DATE',
			allowNull: true,
			label: `Érték dátumidő`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'rangedate',
			order: 55,
		},
		parameter_value_time: {
			type: 'TIME',
			allowNull: true,
			label: `Érték idő`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'rangetime',
			order: 60,
		},
		parameter_value_boolean: {
			type: 'BOOLEAN',
			allowNull: true,
			label: `Érték YorN`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'boolean',
			order: 65,
		},
		updateUser_name: {
			type: 'STRING',
			label: `Utolsó módosító`,
			showInView: true,
			allowNull: false,
			ismodify: false,
			isNewRow: false,
			filterMode: 'list',
			apiEndpoint: '/users/opt/userName',
			orginalName: '$updateUser.name$',
			myparent: 'updated_user',
			order: 91,
		},
		updated_user: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		updatedAt: {
			type: 'DATE',
			label: `Modosítás ideje`,
			allowNull: false,
			showInView: true,
			ismodify: false,
			isNewRow: false,
			filterMode: 'rangedate',
			order: 92,
		},
	};

	useEffect(() => {
		setMeta(modifiedMeta);
		setIsLoading(false);
	}, []);

	// Töltés jelzése, amíg a `meta` be nem töltődik
	if (isLoading) {
		return <div>Töltés...</div>;
	}
	
	return (
		<Screen
			title="Rendszer paraméterek"
			gridActions={gridActions}
			rowActions={rowActions}
			detailRowActions={detailRowActions}
			readonly={false}
			model={'parameters'}
			meta={meta}
			user={user}
			options={{
				enableDelete: true,
				enableAdd: true
			  }}
		/>
	);
};

export default Parameters;