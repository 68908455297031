import React, { useEffect, useState } from 'react';
import Screen from '../components/Screen';
import '../styles/style.css';
import {useOutletContext} from "react-router-dom";

const Emails = () => {

	const user = useOutletContext();
	const [meta, setMeta] = useState(null); // meta állapot
	const [isLoading, setIsLoading] = useState(true); // Töltési állapot
/*	const handleSpec = (row) => {
		// Edit logic here
		console.log('handleSpec' + row.id);
	};

	const handleSpecGrid = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('handleSpec' + rowId);
	};
	const handleSpecRow1 = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('handleSpec1: ' + rowId);
	};
	const handleSpecRow2 = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('handleSpec2: ' + rowId);
	};*/

	//spec gomb hozzáadás és handleSpec függvénybe soron végzett művelet visszaadása
	const rowActions = [];

	//spec gridgomb hozzáadás és handleSpec függvénybe griden végzett művelet visszaadása
	const gridActions = [];

	const modifiedMeta = {
		id: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
			order: 10,
		},
		
		emailType_parameter_value_char: {
			allowNull: false,
			label: `Típus`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			isList: true,
			filterMode: 'list',
			apiEndpoint: '/parameters/opt/parameterName?parameter_type=emailType',
			orginalName: '$emailType.parameter_value_char$',
			myparent: 'type',
			order: 20,
		},
		type: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},

		subject: {
			allowNull: false,
			label: `Tárgy`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 30,
		},
		content: {
			allowNull: false,
			label: `Szöveg`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 40,
			isMemo: true,
		},

		updateUser_name: {
			type: 'STRING',
			label: `Utolsó módosító`,
			showInView: true,
			allowNull: false,
			ismodify: false,
			isNewRow: false,
			filterMode: 'list',
			apiEndpoint: '/users/opt/userName',
			orginalName: '$updateUser.name$',
			myparent: 'updated_user',
			order: 91,
		},
		updated_user: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},

	};

	useEffect(() => {
		setMeta(modifiedMeta);
		setIsLoading(false);
	}, []);

	// Töltés jelzése, amíg a `meta` be nem töltődik
	if (isLoading) {
		return <div>Töltés...</div>;
	}

	return (
		<Screen
			title="Emailek"
			gridActions={gridActions}
			rowActions={rowActions}
			readonly={false}
			model={'emails'}
			meta={meta}
			user={user}
			options={{
				enableDelete: false,
				enableAdd: false
			  }}
		/>
	);
};

export default Emails;