import React, {  useState } from 'react';
import Header from './basicScreenElement/Header';
import Modal from './Modal';
import '../styles/style.css';
import AlertModal from "./AlertModal";
import ErrorBoundary from "./ErrorBoundary";
import DataGridMRT from "./basicScreenElement/DataGridMRT";

const Screen = ({ title, gridActions, rowActions, detailRowActions,readonly, model, meta, user, options }) => {
	console.log('[Screens.fetchdata] meta', meta);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [alertMessage] = useState('');
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const closeAlert = () => {
		setIsAlertOpen(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	console.log('screen',user);

	return (
		<div className="screen">
			<Header title={title} readonly={readonly} />
			<ErrorBoundary>
			<DataGridMRT
				model={model} modifiedMeta={meta}
			  rowActions={rowActions} gridActions={gridActions} detailRowActions={detailRowActions}
		    readonly={readonly} title={title} user={user}
		    options={options}
			/>
			</ErrorBoundary>
{/*			<Footer />*/}
			<AlertModal
				isOpen={isAlertOpen}
				message={alertMessage}
				onClose={closeAlert}
			/>
			{isModalOpen && <Modal onClose={closeModal} />}
		</div>
	);
};

export default Screen;
