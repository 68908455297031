import React from 'react';

const TriStateToggle = ({ value, onChange, readOnly }) => {
	const handleClick = () => {

		if (readOnly) return;
		const nextValue = value === null ? true : value === true ? false : null;
		onChange(nextValue);
	};

	return (
		<button
      type="button"
      onClick={handleClick}
      disabled={readOnly}
      className={value === true ? 'boolean-button-true' : value === false ? 'boolean-button-false' : 'boolean-button-null'}
		>
			{value === true ? '✓' : value === false ? '✗' : '⚊'}
		</button>
	);
};

export default TriStateToggle;