import React, {useEffect, useState} from 'react';
import Screen from '../components/Screen';
import '../styles/style.css';
import { useOutletContext } from 'react-router-dom';

const Users = () => {
	const user = useOutletContext();
	const [meta, setMeta] = useState(null); // meta állapot
	const [isLoading, setIsLoading] = useState(true); // Töltési állapot

/*	const handleSpec = (row) => {
		// Edit logic here
		console.log('handleSpec' + row.id);
	};
	const handleSpecGrid = (rowId) => {
		//Itt a sor ID értékét kapod vissza
		console.log('handleSpec' + rowId);
	};*/

	//spec gomb hozzáadás és handleSpec függvénybe soron végzett művelet visszaadása
	const rowActions = [];

	//spec gridgomb hozzáadás és handleSpec függvénybe griden végzett művelet visszaadása
	const gridActions = [];


	const detailRowActions = [
		//Nem kell frissíteni benne. Minden dinamikus gomb onclick eseménye után frissít a komponens
		/*{ label: 'Törés', onClick: delRowService, isEnableReadOnly: false },*/
	];

	const modifiedMeta = {
		id: {
			type: 'INTEGER',
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		name: {
			type: 'STRING',
			label: `Név`,
			allowNull: false,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			//defaultInsertFilter: true, // Alapértelmezett szűrő beállítása a felvitelkor
			order: 20,
		},
		username: {
			type: 'STRING',
			allowNull: false,
			unique: true,
			label: `Felhasználónév`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 25,
		},
		password: {
			type: 'STRING',
			label: `Jelszó`,
			allowNull: false,
			showInView: false,
			ismodify: false,
			isNewRow: true,
			order: 30,
			filterMode: 'contains',
		},
		role: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		userRole_parameter_value_char: {
			type: 'STRING',
			allowNull: false,
			label: `Jogosultság`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			isList: true,
			filterMode: 'list',
			apiEndpoint: '/parameters/opt/parameterName?parameter_type=userRole',
			orginalName: '$userRole.parameter_value_char$',
			myparent: 'role',
			order: 35,
		},
		email: {
			type: 'STRING',
			allowNull: false,
			label: 'E-mail cím',
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 37,
		},
		worktime: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		userWorktime_parameter_value_num1: {
			type: 'INTEGER',
			allowNull: false,
			label: `Munkaidő`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			isList: true,
			filterMode: 'list',
			apiEndpoint: '/parameters/opt/paramNum?parameter_type=userWorktime',
			orginalName: '$userWorktime.parameter_value_num1$',
			myparent: 'worktime',
			order: 40,
		},
		isHomeOffice: {
			type: 'BOOLEAN',
			allowNull: true,
			label: `HomeOffice`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'boolean',
			order: 45,
		},
		isActive: {
			type: 'BOOLEAN',
			allowNull: false,
			defaultValue: true,
			label: `Aktív-e`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'boolean',
			order: 50,
		},
		createdAt: {
			type:'DATE',
			allowNull: false,
			label: `Létrehozás`,
			showInView: true,
			ismodify: false,
			isNewRow: false,
			filterMode: 'rangedate',
			order: 55,
		},
		comment: {
			type: 'TEXT',
			isMemo: true,
			allowNull: true,
			label: `Megjegyzés`,
			showInView: true,
			ismodify: true,
			isNewRow: true,
			filterMode: 'contains',
			order: 60,
		},
		updated_user: {
			showInView: false,
			ismodify: false,
			isNewRow: false,
		},
		updateUser_name: {
			type: 'STRING',
			label: `Utolsó módosító`,
			showInView: true,
			allowNull: false,
			ismodify: false,
			isNewRow: false,
			filterMode: 'list',
			apiEndpoint: '/users/opt/userName',
			orginalName: '$updateUser.name$',
			myparent: 'updated_user',
			order: 91,
		},
		updatedAt: {
			label: `Modosítás ideje`,
			type: 'DATE',
			allowNull: false,
			showInView: true,
			ismodify: false,
			isNewRow: false,
			filterMode: 'rangedate',
			order: 92,
		},
	};

	useEffect(() => {
		setMeta(modifiedMeta);
		setIsLoading(false);
	}, []);

	// Töltés jelzése, amíg a `meta` be nem töltődik
	if (isLoading) {
		return <div>Töltés...</div>;
	}

	return (
			<Screen
				title="Felhasználó"
				gridActions={gridActions}
				rowActions={rowActions}
				detailRowActions={detailRowActions}
				readonly={false}
				model={'users'}
				meta={meta}
				user={user}
				options={{
					enableDelete: true,
					enableAdd: true
				  }}
			/>
	);
};

export default Users;