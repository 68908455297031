import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ options, value, onChange, readOnly, placeHolder, required, className, classNameList }) => {
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [inputValue, setInputValue] = useState(value || '');
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	// Frissítjük a bejövő opciókat és input értéket
	useEffect(() => {
		setFilteredOptions(options);
	}, [options]);


	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setInputValue(value || '');
	}, [value]);

	useEffect(() => {
		if (inputValue === value) {
			// Ha inputValue megegyezik a value-val, ne szűrjünk, az összes opciót jelenítsük meg
			setFilteredOptions(options);
		} else if (inputValue) {
			// Szűrjük az opciókat, ha inputValue nem egyezik a value-val
			setFilteredOptions(
				options.filter(option =>
					// Ellenőrizzük, hogy option és inputValue string típusúak, és hogy option nem üres string
					option && typeof option === 'string' && option.trim() !== '' &&
					inputValue && typeof inputValue === 'string' &&
					option.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		} else {
			// Ha nincs inputValue, akkor jelenítsük meg az összes opciót
			setFilteredOptions(options);
		}
	}, [options, inputValue, value]);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
		setIsOpen(true);
	};

	const handleSelect = (option) => {
		onChange(option === '' ? null : option);
		setInputValue(option);
		setIsOpen(false);
	};

	const handleClear = () => {
		onChange(null);
		setInputValue('');
		setIsOpen(false);
	};

	return (
		<div className={className} ref={dropdownRef}>
			<input
				type="text"
				value={inputValue}
				onChange={handleInputChange}
				disabled={readOnly}
				placeholder={placeHolder}
				onClick={() => setIsOpen(!isOpen)}
				required={required}
			/>
			{isOpen && !readOnly && (
				<ul className={classNameList}>
					{filteredOptions.length > 0 ? (
						filteredOptions.map((option, index) => (
							<li key={index} onClick={() => handleSelect(option)}>
								{option}
							</li>
						))
					) : (
						<li>No options found</li>
					)}
					<li onClick={handleClear} style={{ cursor: 'pointer', color: 'red' }}>
						Clear
					</li>
				</ul>
			)}
		</div>
	);
};

export default Dropdown;
