import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LuSettings,LuLogOut,LuUserCog } from 'react-icons/lu'; //https://react-icons.github.io/react-icons/icons/lu/
import { TbMail, TbSeparatorVertical } from "react-icons/tb";     //https://github.com/tabler/tabler-icons
import { FaRegUser,FaToolbox } from "react-icons/fa";

import '../styles/style.css';
import logo from '../assets/img/kont_logo.jpg';
import tap_it_logo from '../assets/img/tapi_it_logo.jpg';

const MainLayout = ({ user }) => {

	const navigate = useNavigate();
	const handleLogout = () => {
		localStorage.removeItem('token');
		navigate('/login');
	};

	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

	const toggleSubMenu = () => {
		setIsSubMenuOpen(!isSubMenuOpen);
	};


	const hasAccess = user.role === 'admin' || user.role === 'boss';

	return (
		<div className="main-layout">
			<div className="container">
				<aside className="menu">
					<img src={logo} alt="logo" width="200" height="100"/>
					<ul className={'menu-screens'}>
						{/*<li><button onClick={() => navigate('/contracts')}><LuClipboardSignature style={{ fontSize: '24px',margin: 'auto 0', height: '100%' }}/><p>Feladatok</p></button></li>*/}
						<li><button onClick={() => navigate('/tasks')}><FaToolbox  style={{ fontSize: '24px',margin: 'auto 0', height: '100%' }}/><p>Feladatok</p></button></li>
						<li className={"submenu-main"}>
							<button onClick={toggleSubMenu}><LuSettings style={{fontSize: '24px',margin: 'auto 0', height: '100%'  }}/><p>Beállítások</p></button>
							{isSubMenuOpen && (
								<ul className="submenu">
									<li>
										<button onClick={hasAccess ? () => navigate('/parameters') : null}
											className={`menu-button ${hasAccess ? 'active' : 'inactive'}`}>
											<TbSeparatorVertical  style={{ fontSize: '24px',margin: 'auto 0', height: '100%'  }} />
											<p>Rendszer paraméterek</p>
										</button>
									</li>
								</ul>
							)}
							{isSubMenuOpen && (
								<ul className="submenu">
									<li>
										<button onClick={hasAccess ? () => navigate('/emails') : null}
										        className={`menu-button ${hasAccess ? 'active' : 'inactive'}`}>
											<TbMail style={{ fontSize: '24px',margin: 'auto 0', height: '100%'  }} />
											<p>E-mailek</p>
										</button>
									</li>
								</ul>
							)}
							{isSubMenuOpen && (
							<ul className="submenu">
								<li>
									<button onClick={hasAccess ? () => navigate('/users') : null}
									        className={`menu-button ${hasAccess ? 'active' : 'inactive'}`}>
										<FaRegUser style={{ fontSize: '24px',margin: 'auto 0', height: '100%'  }}/>
										<p>Felhasználók</p>
									</button>
								</li>
							</ul>
						)}
						</li>
					</ul>
					<ul className={'menu-logout'}>
						<li><button><LuUserCog style={{ fontSize: '24px',margin: 'auto 0', height: '100%'  }}/><p>{user.username}</p></button></li>
						<li><button onClick={handleLogout}><LuLogOut style={{ fontSize: '24px',margin: 'auto 0', height: '100%'  }}/><p>Kijelentkezés</p></button></li>
					</ul>
					<ul className={'menu-logout'}>
						<img src={tap_it_logo} alt="TAP IT" width="180"/>
					</ul>
				</aside>
				<div className={"container-content"}>
					<header className="header">
					</header>
					<main className="content">
						<Outlet context={ user } />
					</main>
				</div>
			</div>
		</div>
	);
};

export default MainLayout;
