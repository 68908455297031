import React, {useEffect, useMemo, useState,useCallback} from 'react';
import {MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import HelperScreen from '../HelperScreen';
import {Box, Button, IconButton, MenuItem, Select, TextField, Typography} from '@mui/material';
/*import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Helyes import*/
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { format } from 'date-fns';
/*import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import {AccountCircle, Send} from "@mui/icons-material";*/
import AlertModal from "../AlertModal";
import ActionButtons from "./ActionButtons";
import DetailDataGrid from "./DetailDataGrid";
import {TimePicker, DatePicker,DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';

export const  calculateColumnWidth = (headerText, padding = 0) => {
	const charWidth = 7; // Egy karakter szélessége pixelben (becslés)
	return headerText.length * charWidth + 30 + padding; // A padding hozzáadása a szélességhez
};


/*PDF export*/
/*const handleExportRows = (columns, data) => {
	console.log('Data to export:', data); // Ellenőrzés
	const doc = new jsPDF();
	const tableData = data.map((row) => {
		// Feltételezzük, hogy row maga az adatobjektum
		return Object.values(row);
	});
	const tableHeaders = columns.map((c) => c.header);

	autoTable(doc, {
		head: [tableHeaders],
		body: tableData,
	});

	doc.save('mrt-pdf-example.pdf');
};*/

/*CSV export*/
const handleExportCSV = (columns, data) => {
	console.log('Data to export:', data); // Ellenőrzés
	const BOM = "\uFEFF";
	// 1. A táblázat fejlécének létrehozása CSV formátumban
	const tableHeaders = columns.map((c) => c.header).join(';');
	// 2. A táblázat sorainak létrehozása CSV formátumban
	const tableData = data.map((row) => {
	// Csak azokat az oszlopokat vesszük figyelembe, amelyeket exportálni szeretnénk
		return columns.map((col) => {
			const cellValue = row[col.accessorKey];
			// Ha az értékben vessző vagy idézőjel van, akkor idézőjelbe tesszük
			return typeof cellValue === 'string' && (cellValue.includes(',') || cellValue.includes('"'))
				? `"${cellValue.replace(/"/g, '""')}"`
				: cellValue;
		}).join(';');
	});

	const csvContent = [BOM + tableHeaders, ...tableData].join('\n');
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', 'table-data.csv');
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};


const DataGridMRT = ({ model, modifiedMeta, rowActions, gridActions, detailRowActions,readonly, title, user, options}) => {
	// console.log('[DataGridMRT.fetchdata] modifiedMeta', modifiedMeta);
	const [data, setData] = useState([]);
	const [meta, setMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [alertMessage, setAlertMessage] = useState('');
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [helperScreen, setHelperScreen] = useState({ isOpen: false, actionType: null, rowData: null });
	const [filters, setFilters] = useState([]);
	const [selectedRowId, setSelectedRowId] = useState(null); // A kiválasztott sor ID-ja
	const [listItems, setListItems] = useState([]);

	const defaultInsertFilterkey = meta && typeof meta === 'object'
		? Object.entries(meta)
			.filter(([_, attr]) => attr.defaultInsertFilter)
			.map(([key]) => key)
		: [];

	// console.log('modifiedMeta', modifiedMeta);
	
	const handleRowDoubleClick = (action, row) => {
		setSelectedRowId(row.id)
		console.log("setSelectedRowId",selectedRowId)
		openHelperScreen(action, row)
	};

	const lastSelectedRowid = (row) =>{
		console.log('Double-clicked row:', row); // A sor adatai a dupla kattintáskor
	}

	const openHelperScreen = (actionType, row) => {
		setHelperScreen({
			isOpen: true,
			actionType,
			rowData: row
		});
	};

	const copyRow = (row) => {
		console.log("sethelperRow",row)
		setHelperScreen({
			isOpen: true,
			actionType: 'add',
			rowData: row
		});
	};

	const closeHelperScreen = () => {
		setHelperScreen({ isOpen: false, actionType: null, rowData: null });
	};

	const saveHelperScreenAction = (result) => {
		return onSave(result);
		/*setHelperScreen({ isOpen: false, actionType: null, rowData: null });*/
	};

	const saveHelperScreen = async (result) => {
		console.log("DatagridOnsave",result);
		let isSuccess = await onSave(result);
		console.log('isSuccess',isSuccess);
		if (isSuccess) {
			console.log('Onsave Rowaction')
			closeHelperScreen();
		}
	};

	function flattenObject(obj, parentKey = '', result = {}) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const newKey = parentKey ? `${parentKey}_${key}` : key;
				const value = obj[key];

				if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
					// Rekurzív hívás a beágyazott objektumok laposításához
					flattenObject(value, newKey, result);
				} else {
					result[newKey] = value;
				}
			}
		}
		return result;
	}

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			setData([]);
			console.log('fetchdata', filters);
			console.log('params.filters', JSON.stringify(filters));
			const token = localStorage.getItem('token');
			const response = await axios.get(`/${model}`, {
				params: {
					filters: JSON.stringify(filters),
				},
				headers: {
					'Authorization': `Bearer ${token}`,
					'Role': user.role,
					'UserId': user.id,
				},
			});

			console.log('DataGridMRT*fetchData response.data[0]', response.data.data);

			// Laposítjuk a kapott adatokat
			const flattenedData = response.data.data.map(item => flattenObject(item));

			setData(flattenedData);
			console.log('flattened data', flattenedData[0]);

			let metaToSet = modifiedMeta || {};

			if (typeof metaToSet === 'object' && metaToSet !== null) {
				let metaArray = Object.entries(metaToSet);

				metaArray = metaArray.sort((a, b) => {
					const aOrder = a[1].order !== undefined ? a[1].order : Infinity;
					const bOrder = b[1].order !== undefined ? b[1].order : Infinity;
					return aOrder - bOrder;
				});

				metaToSet = Object.fromEntries(metaArray);
			} else {
				console.warn('Meta data is not an object and cannot be sorted');
			}

			console.log("AftermetaToSet", metaToSet);

			await setMeta(metaToSet);
			console.log("Fetchdata mikor");
		} catch (error) {
			setLoading(false);
			if (error.response && error.response.status === 401) {
				alert("Bejelentkezés lejárt kérjük jelentkezz be újra");
				window.location.href = '/login';
			} else {
				console.error('Failed to fetch data:', error);
			}
		}
	}, [filters, model, user.role, user.id, modifiedMeta]);


	// useEffect az API-híváshoz a komponens szintjén


	const fetchListItems = useCallback(async () => {
		try {
			const token = localStorage.getItem('token');
			const dynamicFields = Object.entries(meta).filter(([_, attr]) => attr.apiEndpoint);

			const optionsPromises = dynamicFields.map(async ([key, attr]) => {
				console.log('attr.apiEndpoint', attr.apiEndpoint);
				const response = await axios.get(attr.apiEndpoint, {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				});
				return { key, options: response.data };
			});

			const results = await Promise.all(optionsPromises);

			const newListItems = results.reduce((acc, { key, options }) => {
				acc[key] = options || [];
				return acc;
			}, {});

			setListItems(newListItems);
			console.log('Listitem', newListItems);
		} catch (error) {
			console.error("Hiba az API hívás során:", error);
		} finally {
			setLoading(false);
		}
	}, [meta]);

	const onSave = async (result) => {
		try {
			console.log('saveUser: ' + user.id);
			console.log('result', result);
			const token = localStorage.getItem('token');

			// 1. Ellenőrizzük a result értékeit, hogy üres stringeket null-ra állítsuk
			const processedResult = {};
			for (const key in result) {
				if (result[key] === '') {
					processedResult[key] = null; // Üres stringet null-ra állítunk
				} else {
					processedResult[key] = result[key]; // Egyébként megtartjuk az eredeti értéket
				}
			}


			// 2. Frissítés (PUT) vagy létrehozás (POST) logika
			if (processedResult.id) {
				console.log("IDSprocessedResult",processedResult);
				if (Object.keys(processedResult).length > 1) {
					await axios.put(`/${model}/${processedResult.id}`, {
						...processedResult,
						userId: user.id // A user azonosítót is hozzáadjuk
					}, {
						headers: {
							'Authorization': 'Bearer ' + token
						}
					});
				}
			} else {
				console.log("INSprocessedResult",processedResult);
				await axios.post(`/${model}`, {
					...processedResult,
					userId: user.id // A user azonosítót is hozzáadjuk
				}, {
					headers: {
						'Authorization': 'Bearer ' + token
					}
				});
				if (defaultInsertFilterkey) {
					if (filters.length === 0) {
						onFilterChange([{
							id: defaultInsertFilterkey,
							value: processedResult[defaultInsertFilterkey],
							filterMode: 'contains'
						}]);
					}
				}
			}

			await fetchData();
			return true;
		} catch (error) {
			// Hibakezelés
			if (error.response && error.response.status === 401) {
				alert("Bejelentkezés lejárt kérjük jelentkezz be újra");
				window.location.href = '/login';
			} else {
				console.error('Failed to save ' + model + ': ' + error);
				setAlertMessage('Adatbázis mentés sikertelen ' + model + '. ' + error);
				setIsAlertOpen(true);
			}
			return false;
		}
	};

	useEffect(() => {
		const fetchDataAndMenuItems = async () => {
			await fetchData(model, filters, setData, setMeta, setLoading, setAlertMessage);
			console.log("After fetchData, meta should be set now...");
		};

		fetchDataAndMenuItems();
	}, [model, filters, fetchData]);

	useEffect(() => {
		if (meta && Object.keys(meta).length > 0) {
			fetchListItems();
		}
	}, [meta,fetchListItems]);

	const onFilterChange = (newFilters) => {
		console.log('newFilters', newFilters);
		setFilters((prevFilters) => {
			const updatedFilters = prevFilters.filter(f => !newFilters.some(nf => nf.id === f.id));
			newFilters.forEach((newFilter) => {
				if (newFilter.value !== null && newFilter.value !== undefined) {
					updatedFilters.push(newFilter);
				}
			});
			return updatedFilters;
		});
	};

	const formatDateWithTimeZone = (date) => {
		return new Intl.DateTimeFormat('sv-SE', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			timeZone: 'Europe/Budapest',
			timeZoneName: 'short' // "GMT+1" vagy hasonló formátum
		}).format(date);
	};

	const delDataRow = async (id,arriveModel) => {
		try {
			const token = localStorage.getItem('token');
			await axios.delete(`/${arriveModel}/${id}`, {
				headers: {
					'Authorization': 'Bearer ' + token // A token hozzáadása a kérés fejlécéhez
				}
			});
			await fetchData(); // Frissítsük az adatokat a törlés után
		} catch (error) {
			// Ellenőrizzük, hogy az error válasz 401-es hibát tartalmaz-e
			if (error.response && error.response.status === 401) {
				// Megjelenítünk egy alert üzenetet
				alert("Bejelentkezés lejárt kérjük jelentkezz be újra");
/*				setAlertMessage(error.response.data.message + " " +model);*/
				// Átirányítjuk a felhasználót a /login oldalra
				window.location.href = '/login';
			} else {
				console.error('Error deleting ' + arriveModel + ': ' + error);

				alert("Bejelentkezés lejárt kérjük jelentkezz be újra");
/*				setAlertMessage('Failed to delete ' + model + '.');*/
				setIsAlertOpen(true);
			}
		}
	};

	const columns = useMemo(() => {
		console.log("rendering columns ", meta);
		// Ellenőrizzük, hogy van-e meta adat
		if (!meta || Object.keys(meta).length === 0) return [];

		// Meta adatok alapján hozunk létre oszlopokat
		return Object.entries(meta).map(([key, attribute]) => {
			// Ellenőrizzük, hogy az oszlopot meg kell-e jeleníteni a táblázatban (pl. showInView tulajdonság alapján)
			if (!attribute.showInView || attribute.isMemo) return null;

			if (attribute.filterMode === 'boolean') {
				console.log("DatarendBOOLEANout");
				return {
					accessorKey: key,
					header: attribute.label || key,
					headerTooltip: attribute.hint || '',
					size: calculateColumnWidth(attribute.label,20), // 50px szélesség beállítása
					minSize: 100,
					className:"td-boolean",
					muiTableBodyCellProps: {align: 'center',},
					Cell: ({ cell }) => {
						const value = cell.getValue();
						const cellClass = value === true ? 'boolean-button-true' : value === false ? 'boolean-button-false' : 'boolean-button-null';
						return (
							<span className={cellClass}>
		            {value === true ? '✓' : value === false ? '✗' : '⚊'}
              </span>
						);
					},
					filterFn: (row, columnId, filterValue) => {
						const cellValue = row.getValue(columnId);
						if (filterValue === 'true') return cellValue === true; // Igen (true)
						if (filterValue === 'false') return cellValue === false; // Nem (false)
						if (filterValue === 'empty') return cellValue === null; // Üres értékek
						return true; // Alapértelmezett, ha nincs kiválasztva szűrő
					},
					Filter: ({ column }) => (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Select
								value={column.getFilterValue() || ''}
								onChange={(e) => {

									const newFilterValue = e.target.value;
									column.setFilterValue(e.target.value || undefined);

									// Ellenőrizzük, hogy az `attribute.originalName` létezik-e
									const filterId = attribute.orginalName || column.id;

									onFilterChange(
										newFilterValue
											? [{ id: filterId, value: newFilterValue, filterMode: 'equals' }]
											: [{ id: filterId, value: undefined }]
									);
								}}
								displayEmpty
								sx={{
									minWidth: '263px', // Minimum szélesség
									height: '43px',   // Kisebb magasság
									border: 'none',
								}}
							>
								<MenuItem value="">Összes</MenuItem>
								<MenuItem value="true">Igen</MenuItem>
								<MenuItem value="false">Nem</MenuItem>
								<MenuItem value="empty">Üres</MenuItem>
							</Select>
							<IconButton
								onClick={() => {
									column.setFilterValue(undefined); // A filtert eltávolítjuk az oszlopból
									const filterId = attribute.orginalName || column.id;
									onFilterChange([{ id: filterId, value: undefined }]); // A filter törlését jelezzük az onFilterChange-nek
								}}
								size="small"
								disabled={!column.getFilterValue()} // Inaktív, ha nincs kiválasztott érték
							>
								<ClearIcon />
							</IconButton>
						</Box>
					),
				};
			}

			if (attribute.filterMode === 'rangedateonly' || attribute.filterMode === 'rangedate' || attribute.filterMode === 'rangetime') {
				return {
					accessorKey: key,
					header: attribute.label || key,
					headerTooltip: attribute.hint || '',
					size: calculateColumnWidth(attribute.label, 20),
					minSize: 100,
					filterFn: (row, columnId, filterValue) => {
						const cellValue = new Date(row.getValue(columnId));
						const startDate = filterValue?.startDate ? new Date(filterValue.startDate) : null;
						const endDate = filterValue?.endDate ? new Date(filterValue.endDate) : null;

						if (attribute.filterMode === 'rangetime') {
							const cellTime = format(cellValue, 'HH:mm');
							const startTime = startDate ? format(startDate, 'HH:mm') : null;
							const endTime = endDate ? format(endDate, 'HH:mm') : null;

							if (startTime && endTime) {
								return cellTime >= startTime && cellTime <= endTime;
							} else if (startTime) {
								return cellTime >= startTime;
							} else if (endTime) {
								return cellTime <= endTime;
							}
						} else {
							if (startDate && endDate) {
								return cellValue >= startDate && cellValue <= endDate;
							} else if (startDate) {
								return cellValue >= startDate;
							} else if (endDate) {
								return cellValue <= endDate;
							}
						}

						return true;
					},
					...(attribute.filterMode === 'rangedate' && {
						Cell: ({ cell }) => {
							const dateValue = cell.getValue();
							if (!dateValue) return '';
							const date = new Date(dateValue);
							return format(date, 'yyyy-MM-dd HH:mm');
						}
					}),
					...(attribute.filterMode === 'rangetime' && {
						Cell: ({ cell }) => {
							const [hours, minutes] = (cell.getValue() || '').split(':');
							if (!hours || !minutes) return ''; // Ha nincs érték vagy nem megfelelő a formátum

							const date = new Date();
							date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);

							return format(date, 'HH:mm'); // Csak óra és perc formátumban jelenítjük meg
						},
					}),
					Filter: ({ column }) => {
						// Lekérjük az alapértelmezett értéket a mentett filterValue-ból
						const initialFilterValue = column.getFilterValue() || {};
						const [startDate, setStartDate] = useState(initialFilterValue.startDate || null);
						const [endDate, setEndDate] = useState(initialFilterValue.endDate || null);
						const [okClicked, setOkClicked] = useState(false); // Az "OK" gomb megnyomásának jelzője

/*						const handleFilterChange = () => {
							const filterValue = { startDate, endDate };
							column.setFilterValue(filterValue);

							const filterId = attribute.orginalName || column.id;
							onFilterChange(
								startDate || endDate
									? [{
										id: filterId,
										value: {
											startDate: startDate ? formatDateWithTimeZone(new Date(startDate)) : null,
											endDate: endDate ? formatDateWithTimeZone(new Date(endDate)) : null,
										},
										filterMode: 'range'
									}]
									: []
							);
						};

						useEffect(() => {
							if (okClicked) {
								handleFilterChange();
								setOkClicked(false);
							}
						}, [okClicked, handleFilterChange]);*/

						const handleFilterChange = useCallback(() => {
							const filterValue = { startDate, endDate };
							column.setFilterValue(filterValue);

							const filterId = attribute.orginalName || column.id;
							onFilterChange(
								startDate || endDate
									? [
										{
											id: filterId,
											value: {
												startDate: startDate
													? formatDateWithTimeZone(new Date(startDate))
													: null,
												endDate: endDate
													? formatDateWithTimeZone(new Date(endDate))
													: null,
											},
											filterMode: 'range',
										},
									]
									: []
							);
						}, [startDate, endDate, column]);

						useEffect(() => {
							if (okClicked) {
								handleFilterChange();
								setOkClicked(false);
							}
						}, [okClicked, handleFilterChange]);

						return (
							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
								<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									{attribute.filterMode === 'rangedateonly' ? (
										<>
											<DatePicker
												label="Kezdő dátum"
												value={startDate}
												onChange={setStartDate}
												onClose={() => setOkClicked(true)}
											/>
											<DatePicker
												label="Vég dátum"
												value={endDate}
												onChange={setEndDate}
												onClose={() => setOkClicked(true)}
											/>
										</>
									) : attribute.filterMode === 'rangetime' ? (
										<>
											<TimePicker
												label="Kezdő idő"
												value={startDate}
												onChange={setStartDate}
												onClose={() => setOkClicked(true)}
												views={['hours', 'minutes']} // Másodpercek kihagyása
											/>
											<TimePicker
												label="Vég idő"
												value={endDate}
												onChange={setEndDate}
												onClose={() => setOkClicked(true)}
												views={['hours', 'minutes']} // Másodpercek kihagyása
											/>
										</>
									) : (
										<>
											<DateTimePicker
												label="Kezdő dátumidő"
												value={startDate}
												onChange={setStartDate}
												onClose={() => setOkClicked(true)}
											/>
											<DateTimePicker
												label="Vég dátumidő"
												value={endDate}
												onChange={setEndDate}
												onClose={() => setOkClicked(true)}
											/>
										</>
									)}
									<IconButton
										onClick={() => {
											setStartDate(null);
											setEndDate(null);
											column.setFilterValue(undefined);const
											filterId = attribute.orginalName || column.id;
											onFilterChange([{ id: filterId, value: undefined }]);
										}}
										size="small"
										disabled={!startDate && !endDate}
									>
										<ClearIcon />
									</IconButton>
								</Box>
							</LocalizationProvider>
						);
					},
				};
			}

			if (attribute.filterMode === 'contains') {
				console.log("DatarendSTRINGout", key);
				
			
				return {
					accessorKey: key,
					header: attribute.label || key,
					headerTooltip: attribute.hint || '',
					size: calculateColumnWidth(attribute.label, 20),
					minSize: 100,
					className: "td-string",
					filterFn: (row, columnId, filterValue) => {
						const cellValue = row.getValue(columnId)?.toString().toLowerCase() || ''; // Ha null vagy undefined, akkor üres stringre állítjuk
						const searchValue = filterValue?.toLowerCase();

						// Ellenőrizzük, hogy van-e filterValue és legalább 4 karakter hosszú
						if (!searchValue || searchValue.length < 4) return true;

						return cellValue.includes(searchValue);
					},
					Filter: ({ column }) => {
						const [filterText, setFilterText] = React.useState(column.getFilterValue() || ''); // Kezdeti érték beállítása
						const debounceTimeoutRef = React.useRef(null);

						React.useEffect(() => {
							// Amikor a szűrő újra megnyílik, visszatöltjük a korábbi értéket
							setFilterText(column.getFilterValue() || '');
						}, [column]);

						const handleChange = (e) => {
							const newValue = e.target.value;
							setFilterText(newValue);

							if (debounceTimeoutRef.current) {
								clearTimeout(debounceTimeoutRef.current);
							}
							const filterId = attribute.orginalName || column.id;


							debounceTimeoutRef.current = setTimeout(() => {
								if (newValue.length >= 3) {
									// Csak akkor állítjuk be a szűrést, ha legalább 4 karakter
									if (column.getFilterValue() !== newValue) {
										column.setFilterValue(newValue);
										onFilterChange([{ id: filterId, value: newValue,filterMode: 'contains'}]);
									}
								} else if (column.getFilterValue() !== undefined) {
									// Ha kevesebb, mint 3 karakter, és a szűrő még nincs törölve, akkor töröljük a szűrőt
									column.setFilterValue(undefined);
									onFilterChange([{ id: filterId, value: undefined }]);
								}
							}, 300);
						};

						return (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<TextField
									value={filterText}
									onChange={handleChange}
									placeholder="min. 4 karakter"
									variant="outlined"
									size="small"
									sx={{ minWidth: '263px', height: '43px', border: 'none' }}
								/>
								<IconButton
									onClick={() => {
										setFilterText(''); // Töröljük a bevitelt
										column.setFilterValue(undefined); // A szűrőt eltávolítjuk az oszlopból
										const filterId = attribute.orginalName || column.id;
										onFilterChange([{ id: filterId, value: undefined }]); // A szűrés törlését jelezzük az onFilterChange-nek
									}}
									size="small"
									disabled={!column.getFilterValue()} // Inaktív, ha nincs beírt érték
								>
									<ClearIcon />
								</IconButton>
							</Box>
						);
					},
				};
			}

			if (attribute.filterMode === 'rangenumber') {
				console.log("DatarendINTEGERout");
				return {
					accessorKey: key,
					header: attribute.label || key,
					headerTooltip: attribute.hint || '',
					size: calculateColumnWidth(attribute.label, 20),
					minSize: 100,
					className: "td-integer",
					filterFn: (row, columnId, filterValue) => {
						const cellValue = row.getValue(columnId);
						if  (!filterValue || (filterValue.min === undefined && filterValue.max === undefined)) return true;

						const { min, max } = filterValue;
						return !((min !== undefined && cellValue < min) || (max !== undefined && cellValue > max));

					},
					Filter: ({ column }) => {
						const [minValue, setMinValue] = React.useState('');
						const [maxValue, setMaxValue] = React.useState('');

						React.useEffect(() => {
							const filterValue = column.getFilterValue() || {};
							setMinValue(filterValue.min !== undefined ? filterValue.min : '');
							setMaxValue(filterValue.max !== undefined ? filterValue.max : '');
						}, [column]);

						const handleFilter = () => {
							const min = minValue ? parseInt(minValue, 10) : undefined;
							const max = maxValue ? parseInt(maxValue, 10) : undefined;

							const filterId = attribute.orginalName || column.id;

							column.setFilterValue({ min, max });
							onFilterChange([{ id: filterId, value: { min, max },filterMode: 'range' }]);
						};

						return (
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<TextField
									value={minValue}
									onChange={(e) => setMinValue(e.target.value)}
									placeholder="Min"
									type="number"
									variant="outlined"
									size="small"
									sx={{ width: '200px' }}
								/>
								<TextField
									value={maxValue}
									onChange={(e) => setMaxValue(e.target.value)}
									placeholder="Max"
									type="number"
									variant="outlined"
									size="small"
									sx={{ width: '200px' }}
								/>
								<Button
									onClick={handleFilter}
									variant="contained"
									size="small"
								>
									Szűrés
								</Button>
								<IconButton
									onClick={() => {
										setMinValue('');
										setMaxValue('');
										column.setFilterValue(undefined);
										const filterId = attribute.orginalName || column.id;
										onFilterChange([{ id: filterId, value: undefined }]);
									}}
									size="small"
									disabled={!column.getFilterValue()}
								>
									<ClearIcon />
								</IconButton>
							</Box>
						);
					},
				};
			}


			if (attribute.filterMode === 'list') {
				console.log('datarendlist');
				return {
					accessorKey: key,
					header: attribute.label || key,
					headerTooltip: attribute.hint || '',
					size: calculateColumnWidth(attribute.label, 20),
					minSize: 100,
					className: 'td-dynamic-list',
					muiTableBodyCellProps: { align: 'left' },
					filterFn: (row, columnId, filterValue) => {
						const cellValue = row.getValue(columnId);
						return filterValue ? cellValue === filterValue : true; // Alapértelmezett szűrő feltétel
					},
					Filter: ({ column }) => (
						<Box sx={{ display: 'flex', alignItems: 'left' }}>
							<Select
								value={column.getFilterValue() || ''}
								onChange={(e) => {
									const newFilterValue = e.target.value;
									column.setFilterValue(newFilterValue || undefined);

									// Ellenőrizzük, hogy az `attribute.originalName` létezik-e
									onFilterChange(
										newFilterValue
											? [{ id: attribute.myparent, value: newFilterValue, filterMode: 'equals' }]
											: [{ id: attribute.myparent, value: undefined }]
									);
								}}
								displayEmpty
								sx={{
									minWidth: '263px', // Minimum szélesség
									height: '43px', // Kisebb magasság
									border: 'none',
								}}
							>
								<MenuItem value="">Összes</MenuItem>
								{listItems[key] && listItems[key].length > 0
									? listItems[key].map((item, index) => (
										<MenuItem key={index} value={item.id}>
											{item.value}
										</MenuItem>
									))
									: (
											<MenuItem disabled value="">
												Nincs adat
											</MenuItem>
									)
								}
							</Select>

							<IconButton
								onClick={() => {
									column.setFilterValue(undefined); // A filtert eltávolítjuk az oszlopból
									onFilterChange([{ id: attribute.myparent, value: undefined }]); // A filter törlését jelezzük az onFilterChange-nek
								}}
								size="small"
								disabled={!column.getFilterValue()} // Inaktív, ha nincs kiválasztott érték
							>
								<ClearIcon />
							</IconButton>
						</Box>
					),
				};
			}

			return {
				accessorKey: key, // Az oszlop kulcsa, pl. 'name', 'email'
				header: attribute.label || key, // Az oszlop címkéje, ha van; egyébként a kulcs
				headerTooltip: attribute.hint || '',
				size: calculateColumnWidth(attribute.label, 20), // Dinamikus kezdő méret
				minSize: 100,
				enableSorting: !attribute.isMemo, // A TEXT típusú mezőkön nincs rendezés
			};
		}).filter(Boolean); // Eltávolítjuk a null értékeket (amikor showInView false)
	}, [meta,listItems]);

	// MaterialReactTable konfiguráció
	const table = useMaterialReactTable({
		columns, // Oszlopok a meta adatokból
		data, // Az adatok

		muiTableBodyCellProps: ({ row }) => ({
			onDoubleClick: () => {
				handleRowDoubleClick(readonly ? 'show' : 'modify', row.original);
			},
			onClick: () => {
				lastSelectedRowid(row.original);
			},
			sx: {borderLeft: "1px solid rgba(224, 224, 224, 1)"},
		}),
		//header cella formázás
		muiTableHeadCellProps: {
			sx: {borderLeft: "1px solid white"},
		},
		state: { isLoading: loading },
		enableColumnResizing: true,
		manualFiltering: true,
		muiTableHeadProps:{
			sx: {
				maxHeight: '50px',
			}
		},
		muiTableFooterProps:{
			sx: {
				display: 'none',
				zIndex: '0',
			}
		},
		muiPaginationProps:{
			sx:{
				display: 'none',
				zIndex: '0',
			}
		},
		muiTableProps: {
			sx: {
/*				height: 'calc(100% - 10px)',*/
				width: '100%',
				//ide be lehet tenni egy dinamikus táblázat szélesség állítást az oszlopok számától és milyenségétől függően... Később. most jó így.
				maxWidth: '1800px',
				zIndex: '0',
				borderCollapse: 'collapse',
/*				height: 'calc(100vh - 205px)',
				alignContent: 'baseline',*/

			},
		},

		muiTablePaperProps: {
			sx: {
				height: 'calc(100% - 14px)',
			},
		},
		muiTableContainerProps: {
			sx: {
				maxHeight: 'calc(100% - 70px)',
			},
		},
		muiTableFooterCellProps:{
			sx: {
				height: '10px',
				zIndex: '0',
			}
		},
		enableColumnFilterModes: true, // oszlopszűrők engedélyezése
		columnFilterDisplayMode: 'popover',
		enableColumnOrdering: false, // oszlopok sorrendjének megváltoztatása
		enableGrouping: false, // oszlopok csoportosítása
		enableFacetedValues: true, // szűrés facetták szerint
		enableRowActions: true, // sorok melletti akciók
		enableRowSelection: false, // sorok kiválasztása
		enableRowPinning: false,   //Endegélyezni lehet egy sor megfogását hogy össze lehessen hasonlítani egy másikkal
		rowPinningDisplayMode: 'top', // default
		enableSelectAll: false,
		enableClickToCopy: false,
		enableExpandAll: false,
		enableMultiSort: false,
		enableStickyHeader: true,
		enableStickyFooter: false,
		enableColumnActions: false,   // colomnok melletti akciók
		positionGlobalFilter: 'right',
		initialState: {
			showColumnFilters: true, // oszlopszűrők kezdetben láthatóak
			showGlobalFilter: true, // globális keresőmező látható
			density: 'compact', // sűrűség*/
			columnPinning: {
				left: ['mrt-row-expand'], // rögzített oszlopok bal oldalon
				right: ['mrt-row-actions'], // rögzített oszlopok bal oldalon
			},
		},
		muiSelectCheckboxProps: {
			size: 'small',
			sx: {
				minWidth: 'unset',
			},
		},
		muiTableFooterRowProps: {sx: {display: 'none'}},
		muiDetailPanelProps: {
			sx: {
				width:'98%',
				display:'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				padding:'0 2px',
			},
		},
/*		muiTableBodyRowProps:  ({ row }) => ({
			hover: true,
			sx: {
				backgroundColor: "red",
			},
		}),*/
		muiTableBodyRowProps: ({ row }) => {
			let conditionalStyle = undefined;

			Object.entries(meta).some(([key, attribute]) => {

				if (attribute.conditionalFormat) {

					const condition = attribute.conditionalFormat.if;
					const style = attribute.conditionalFormat.style;
					const rowValue = row.original[key];


					// Feltétel kiértékelése (pl. rowValue > sysdate)
					try {
						/*  Így kell majd megcsinálni, mert biztonsági konckázat a dinamikus js végrehajtás.
						const conditionsMap = {
  greaterThanTen: (rowValue) => rowValue > 10,
  isEven: (rowValue) => rowValue % 2 === 0,
};

const condition = "greaterThanTen"; // Ez dinamikusan jön
const evaluateCondition = conditionsMap[condition];

if (evaluateCondition) {
  console.log(evaluateCondition(15)); // true
}
						 */

						// A feltétel kiértékeléséhez a rowValue átadásra kerül a feltételbe
						const evaluateCondition = new Function('rowValue', `return ${condition}`);
						const isConditionTrue = evaluateCondition(rowValue);

						if (isConditionTrue) {
							conditionalStyle = style; // Ha a feltétel igaz, beállítjuk a style értéket
							return true; // Ha a feltétel igaz, itt leállítjuk a további keresést
						}
					} catch (error) {
						console.error("Invalid condition:", condition, error);
					}
				} else {
				}
				return false;
			});
			return {
				hover: true,
				sx: {
					...(conditionalStyle || {}), // Ha van conditionalStyle, akkor hozzáadjuk az sx-hez
				},
/*					sx: {backgroundColor: 'red'},*/
			};
		},
		enablePagination: false,
		enableRowNumbers: true,
		enableMultiRowSelection:false,
		positionToolbarAlertBanner: 'bottom', // figyelmeztetések helye
		muiSearchTextFieldProps: {
			size: 'small',
			variant: 'outlined',
		},
		muiExpandButtonProps: ({ row }) => ({
			sx: {
				transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
				transition: 'transform 0.2s',
			},
		}),
		muiColumnDragHandleProps: {sx: {backgroundColor: 'red'}},
		localization: {
			noResultsFound: 'Nincs találat',
			loading: 'Betöltés...',
			showHideColumns: 'Oszlopok mutatása/elrejtése',
			goToPage: 'Ugrás az oldalra',
			rowActions: 'Sor műveletek',
			showAll: 'Mutass mindent',
			showLess: 'Mutass kevesebbet',
			rowsPerPage: 'Sorok oldalanként',
			rowSelected: 'sor kiválasztva',
			rowsSelected: 'sorok kiválasztva',
			filter: 'Szűrés',
			clearFilter: 'Szűrő törlése',
			clearFilters: 'Összes szűrő törlése',
			search: 'Keresés',
			sortByColumnAsc: 'Rendezés növekvő sorrendben',
			sortByColumnDesc: 'Rendezés csökkenő sorrendben',
			filterModeEquals: 'Egyenlő',
			filterModeStartsWith: 'Kezdődik',
			filterModeEndsWith: 'Végződik',
			filterModeLessThan: 'Kisebb mint',
			filterModeGreaterThan: 'Nagyobb mint',
			filterModeEmpty: 'Üres',
			filterModeNotEmpty: 'Nem üres',
			filterModeBetween: 'Közötti',
			clearSort: 'Rendezés törlése',
			filterByColumn: 'Szűrés',
			groupByColumn: 'Csoportosítás',
			resetColumnSize: 'Oszlopméret visszaállítás',
			hideColumn: 'Oszlop elrejtése',
			showAllColumns: 'Minden oszlop mutatása',
			filterContains: 'Tartalmazza',
			filterEquals: 'Egyenlő',
			filterStartsWith: 'Kezdődik',
			filterEndsWith: 'Végződik',
			filterNotEquals: 'Nem egyenlő',
			filterLessThan: 'Kisebb, mint',
			filterGreaterThan: 'Nagyobb, mint',
			filterLessThanOrEqualTo: 'Kisebb vagy egyenlő',
			filterGreaterThanOrEqualTo: 'Nagyobb vagy egyenlő',
			filterEmpty: 'Üres',
			filterNotEmpty: 'Nem üres',
			filterBetween: 'Között',
			filterFuzzy: 'Majdnem :-)',
			filterBetweenInclusive: 'Közte',
		},
		renderRowActionMenuItems: ({ closeMenu, row }) => {
			return [
				(
					// Fix "Delete" gomb hozzáadása
					<MenuItem
						key="delete"
						onClick={() => {
							// Meghívjuk az aktuális komponensben lévő delDataRow függvényt
							delDataRow(row.original.id,model);
							closeMenu();
						}}
						sx={{ m: 0 }}
						// A "Delete" gomb csak akkor engedélyezett, ha readonly: false
						disabled={readonly || !options.enableDelete}
					>
						Törlés {/* Címke */}
					</MenuItem>
				),
				(
					// Fix "Sormásolás" gomb hozzáadása
					<MenuItem
						key="copy"
						onClick={() => {
							copyRow(row.original);
							closeMenu();
						}}
						sx={{ m: 0 }}
						disabled={readonly|| !options.enableAdd}
					>
						Sor másolás {/* Címke */}
					</MenuItem>
				),
				// Dinamikus gombok hozzáadása
				...rowActions.map((action, actionIndex) => (
					<MenuItem
						key={actionIndex}
						onClick={() => {
							action.onClick(); // Meghívjuk az action onClick függvényét
							closeMenu(); // Bezárjuk a menüt
						}}
						sx={{ m: 0 }}
						disabled={readonly && !action.isEnableReadOnly}
					>
						{action.label} {/* Dinamikus címke */}
					</MenuItem>
				))
			];
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box
				sx={{
					display: 'flex',
					gap: '16px',
					padding: '8px',
					flexWrap: 'nowrap',
					width: '50%',
					maxWidth: '50%',
					zIndex: '0',
				}}
			>
				<Button
					disabled={table.getPrePaginationRowModel().rows.length === 0}
					onClick={() => {
						// Szűrjük meg az oszlopokat és adatokat a megfelelő exporthoz
						const exportColumns = columns.filter(column => column.showInView !== false);
						const exportData = table.getPrePaginationRowModel().rows.map(row => {
							const filteredRow = {};
							exportColumns.forEach(column => {
								filteredRow[column.accessorKey] = row.original[column.accessorKey];
								if(meta[column.accessorKey]) {
									if(meta[column.accessorKey].isforeignKeyChild){
										const columnPath = meta[column.accessorKey].showColumnName.split('.'); // Pl.: ['Customer', 'name']
										filteredRow[column.accessorKey] = row.original[columnPath[0]][columnPath[1]];
									}
								}
							});
							return filteredRow;
						});
						handleExportCSV(exportColumns, exportData);
					}}
					startIcon={<FileDownloadIcon />}
				>CSV export
				</Button>
				<div>
					<ActionButtons
						meta={meta}
						gridActions={gridActions}
						readonly={readonly}
						onSave={saveHelperScreenAction}
						title={title}
						dynamicOptions={listItems}
						options={options}
					/>
				</div>
			</Box>
		),
			renderDetailPanel: ({ row }) => {
				const textFields = Object.entries(meta)
					.filter(([, attribute]) => attribute.isMemo) // Csak TEXT/MEMO típusú mezők
					.map(([key, attribute]) => (
						<Box key={key}
						     sx={{ margin: '5px 20px 5px 0', padding: '0 0 10px 0', width: 'calc(50vw - 200px)', maxWidth: '690px', minWidth: '345px' }}
						     className={'textfieldbox'}
						>
							<Typography variant="subtitle2" color="textSecondary"
							            sx={{ fontSize: '0.875rem', fontWeight: '700', color: 'rgba(0, 0, 0, 0.87)' }}>
								{attribute.label || key}:
							</Typography>
							<Typography variant="body1"
							            sx={{
								            fontSize: '14px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.87)', border: '1px solid #cfd5ca',
								            padding: '10px 5px', borderRadius: '5px', minHeight: '50px',
								            maxHeight: '150px',
								            overflow: 'auto',
							            }}>
								{row.original[key] || ''}
							</Typography>
						</Box>
					));

				const detailFields = Object.entries(meta)
					.filter(([, attribute]) => attribute.isDetail) // Csak azokat a mezőket választjuk ki, ahol isDetail: true
					.map(([key, attribute]) => (
						<div key={key} className={'detail-grid-div'}>
							<DetailDataGrid
								endpoint={attribute.apiDetailEndpoint}
								contractId={row.original.id}
								detailModel={attribute.detailModel}
								detailRowActions={detailRowActions}
								readOnly={readonly}
								options={options}
							/>
						</div>
					));

				if (textFields.length === 0 && detailFields.length === 0) {
					return null;
				}

				return (
					<Box
						sx={{
							padding: '5px 10px',
							borderRadius: '5px',
							backgroundColor: '#f4f4f4',
							minWidth: '800px',
							maxWidth: 'calc(100vw - 300px)',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: 2, // kis távolság a textFields és detailFields között
						}}
					>
						{textFields.length > 0 && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									flexWrap: 'wrap',
									gap: 1,
								}}
							>
								{textFields}
							</Box>
						)}

						{detailFields.length > 0 && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 2,
									width: '100%',
								}}
							>
								{detailFields}
							</Box>
						)}
					</Box>
				);
			},

	});

	const closeAlert = () => {
		setIsAlertOpen(false);
	};

	return (
		<>
			<div className={"tableContent"}>
				{loading ? ( // Ha betöltés folyamatban van, mutatunk egy töltést
					<div>Loading...</div>
				) : (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
						<MaterialReactTable
							table={table}
							enablePagination={false}
							enableRowVirtualization={true}
						/>
					</LocalizationProvider>
				)}
			</div>
			<AlertModal
				isOpen={isAlertOpen}
				message={alertMessage}
				onClose={closeAlert}
			/>
			<HelperScreen
				isOpen={helperScreen.isOpen}
				actionType={helperScreen.actionType}
				rowData={helperScreen.rowData}
				onClose={closeHelperScreen}
				onSave={saveHelperScreen}
				meta={meta}
				title={title}
				dynamicOptions={listItems}
			/>
		</>
	);
};

export default DataGridMRT;