import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/img/kont_logo.jpg';

const Login = () => {
	const [form, setForm] = useState({ username: '', password: '' });
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal állapota
	const [user, setUser] = useState(''); // User input az elfelejtett jelszóhoz
	const [message, setMessage] = useState(''); // Üzenet a jelszó-visszaállításhoz
	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
		setError('');
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			console.log('NA és akkor most?')
			const apiUrl = process.env.REACT_APP_API_URL || 'http://nincsbeallitvaurl.com';
			console.log('apiUrl',apiUrl);
			const res = await axios.post(`${apiUrl}/auth/login`, form);
			//const res = await axios.post('http://kontener.4profitmarketing.hu:5000/auth/login', form);
			await localStorage.setItem('token', res.data.token);
			axios.defaults.headers.common['Authorization'] = `Bearer ${(localStorage.getItem('token'))}`;
			alert('Sikeres bejelentkezés!');
			navigate('/');
		} catch (err) {
			setError('Invalid credentials');
		}
	};

	// Elfelejtett jelszó modal megnyitása
	const openForgotPasswordModal = () => {
		setIsModalOpen(true);
		setMessage('');
		setUser('');
	};

	// Modal bezárása
	const closeForgotPasswordModal = () => {
		setIsModalOpen(false);
	};

	// Email küldése jelszó-visszaállításhoz
	const handleForgotPassword = async () => {
		try {
			const res = await axios.post(process.env.REACT_APP_API_URL + '/auth/forgot-password', { user });
			setMessage('Jelszó-visszaállító email elküldve!');
		} catch (err) {
			setMessage('Hiba történt az email küldésekor!', err);
		}
	};
	
	return (		<div className="main-layout">
		<header className="header">
			<img src={logo} alt="logo" width="200" height="100"/>
			<h1>Konténer Centrum</h1>
		</header>
		<div className="container">
			<aside className="menu">
			</aside>
			<main className="content">
				<div className="container_login">
					<h2>Bejelentkezés</h2>
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="username">Felhasználó név:</label>
							<input
								type="text"
								className="form-control"
								id="username"
								name="username"
								value={form.username}
								onChange={handleChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="password">Jelszó:</label>
							<input
								type="password"
								className="form-control"
								id="password"
								name="password"
								value={form.password}
								onChange={handleChange}
								required
							/>
						</div>
						<div className="form-group-button">
							<button type="submit" className="btn btn-primary">
							Login
							</button>
							{error && <button className="pass-rember" onClick={openForgotPasswordModal}>
								Elfelejtett jelszó?
							</button>}
						</div>
					</form>
					{error && <div className="alert alert-danger">Felhasználónév vagy jelszó nem megfelelő</div>}
				</div>
			</main>
		</div>
		{/* Elfelejtett jelszó modális ablak */}
		{isModalOpen && (
			<div className="alert-modal">
				<div className="alert-modal-content">
					<div className="helper-header">
						<h2>Elfelejtett jelszó</h2>
						<button type="button" onClick={closeForgotPasswordModal}>X</button>
					</div>
					<div className='alert-modal-content-data'>
						<label htmlFor="user">Adja meg a felhasználónevét:</label>
						<input type="text"	id="user" className="form-control"	value={user}
						       onChange={(e) => setUser(e.target.value)} required/>
						<div className="alert-modal-button">
							<button className="btn btn-primary" onClick={handleForgotPassword}>
								Küldés
							</button>
						</div>
						{message && <div className="alert alert-info">{message}</div>}
					</div>
				</div>
			</div>


/*   <div className="alert-modal">
			<div className="alert-modal-content">
				<h2>Elfelejtett jelszó</h2>
				<label htmlFor="email">Adja meg az email címét:</label>
				<input type="email"	id="email" className="form-control"	value={email} 
					onChange={(e) => setEmail(e.target.value)} required/>
				<div className="modal-buttons">
					<button className="btn btn-primary" onClick={handleForgotPassword}>
						Küldés
					</button>
					<button className="btn btn-secondary" onClick={closeForgotPasswordModal}>
						Bezár
					</button>
				</div>
				{message && <div className="alert alert-info">{message}</div>}
          	</div>
        </div>*/
      	)}
	</div>
	);
};

export default Login;
