import React, { useState } from 'react';
import HelperScreen from "../HelperScreen";
import { Button } from '@mui/material';

const ActionButtons = ({ data, meta, gridActions, readonly, onSave, title, user, dynamicOptions, options }) => {
	const [helperScreen, setHelperScreen] = useState({ isOpen: false, actionType: null, rowData: null });

	const closeHelperScreen = () => setHelperScreen({ isOpen: false, actionType: null, rowData: null });

	const saveHelperScreenAction = async (result) => {
		if (await onSave(result)) closeHelperScreen();
	};

	const addData = (row = {}) => setHelperScreen({ isOpen: true, actionType: 'add', rowData: row });

	return (
		<>
			<div className="mb-3">
				{options.enableAdd && (
					<Button
						onClick={() => addData({})} // Így biztosítjuk, hogy az addData paraméterként egy üres objektumot kapjon
						disabled={readonly && !options.enableAdd}
						style={readonly && !options.enableAdd ? { display: 'none' } : {}}
					>
						Új sor
					</Button>
				)}
				{gridActions.map((action, index) => (
					<Button key={index} onClick={action.onClick}>
						{action.label}
					</Button>
				))}
			</div>
			<HelperScreen
				isOpen={helperScreen.isOpen}
				actionType={helperScreen.actionType}
				rowData={helperScreen.rowData}
				onClose={closeHelperScreen}
				onSave={saveHelperScreenAction}
				meta={meta}
				title={title}
				dynamicOptions={dynamicOptions}
			/>
		</>
	);
};

export default ActionButtons;
