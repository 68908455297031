import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'; // Importáljuk a kívánt ikont

const AlertModal = ({ isOpen, message, onClose }) => {
	if (!isOpen) return null;

	/*	return (
			<div className="alert-modal">
				<div className="alert-modal-content">
					<div className="helper-header">
						<h2>Hiba</h2>
						<FontAwesomeIcon icon={faCircleXmark} />
					</div>
					<div className="alert-modal-content-data">
						<p>{message}</p>
						<button onClick={onClose}>OK</button>
					</div>
				</div>
			</div>
		);*/

	return (
		<div className="alert-modal">
			<div className="alert-modal-content">
				<div className="helper-header">
					<h2>Hiba</h2>
					<button type="button" onClick={onClose}>X</button>
				</div>
				<div className='alert-modal-content-data'>
					<p>{message}</p>
					<div className="alert-modal-button">
					<button   onClick={onClose}>OK</button>
						</div>
				</div>
			</div>
		</div>
	);
};

export default AlertModal;
