// src/components/Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = ({ title}) => {

	const navigate = useNavigate();

	const handleClose = () => {
		navigate('/'); // Visszanavigál a főoldalra vagy a fő elrendezéshez
	};

	return (
		<header>
			<h1>{title}</h1>
			<button onClick={handleClose}>X</button>
		</header>
	);
};

export default Header;
